import React from "react";

export const Services = (props) => {
  return (
    <div id="services" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>Our Services</h2>
          <p>WHAT WE BRING TO THE TABLE is our extensive skills in the following areas.</p>
        </div>
        <div className="row">
         

              {/* CIVIL, ELECTRICAL& MECHANICAL WORKS START */}
                <div
                  key={`mmm`}
                  className="col-md-4 col-sm-6 d-flex align-items-stretch"
                >                  
                  <div className="service-box text-center">
                    <i className="fa fa-building-o"></i>
                    <div className="service-desc">
                      <h3>CIVIL, ELECTRICAL& MECHANICAL WORKS</h3>
                      <p></p>                
                      <div className="services_list"> 
                      <div className="list-styleq">
                      <ul>
                          <li key="">Building Construction & Maintenance</li>
                          <li key="">Earth Work & Excavation </li>
                          <li key="">Gabion Stone Pitching</li>
                          <li key="">Fencing Work (All Type)</li>
                          <li key="">Infrastructure and Steel Structure</li>
                          <li key="">Pipeline Works</li>
                          <li key="">Road Resheeting</li>
                          <li key="">Graded Road Construction</li>
                          <li key="">Land Reclamation</li>
                          <li key="">MEP Works</li>                       
                      </ul> 
                      </div>
                      </div>
                    </div>
                  </div>
                </div>
               {/* CIVIL, ELECTRICAL& MECHANICAL WORKS END */}


         

               {/* Building Construction & Maintenance START */}
                <div
                  key={`mmm`}
                  className="col-md-4 col-sm-6 d-flex align-items-stretch"
                >                  
                  <div className="service-box text-center">
                    <i className="fa fa-home"></i>
                    <div className="service-desc">
                      <h3>Building Construction & Maintenance</h3>
                      <p>Our services begin with pricing and verifying the validity of the bids using our extensive cost histories. During actual construction, we manage the day-to-day activity on the job, including safety, Quality control and adherence the project schedule. Construction supervision during the construction phase, our full supervision team is on the site, monitoring work to assure compliance with specifications, budget and schedule. </p>                
                      <div className="services_list"> 
                      <div className="list-styleq">
                      </div>
                      </div>
                    </div>
                  </div>
                </div>
              {/* Building Construction & Maintenance END */}


              {/* Earth Work & Excavation START */}
              <div
                  key={`mmm`}
                  className="col-md-4 col-sm-6 d-flex align-items-stretch"
                >                  
                  <div className="service-box text-center">
                    <i className="fa fa-anchor"></i>   
                    <div className="service-desc">
                      <h3>Earth Work & Excavation</h3>
                      <p>SiCO performs a variety of earth moving activities including bulk excavation and over lot grading, structural excavation and backfill for buildings and structures, road grading, and final site grading. We perform our job utilizing the latest technology state of the art equipment.</p> 
                      <p><b>Work Capabilities:</b></p>               
                      <div className="services_list"> 
                      <div className="list-styleq">
                      <ul>
                          <li key="">Top Soil Excavation</li>
                          <li key="">Rock & Earth Excavation</li>
                          <li key="">Excavation for pipelines</li>
                          <li key="">Dredge & Channel Excavation</li>                    
                      </ul> 
                      </div>
                      </div>
                    </div>
                  </div>
                </div>
               {/* Earth Work & Excavation END */}


              {/* Gabion Stone Pitching START */}
              <div
                  key={`mmm`}
                  className="col-md-4 col-sm-6 d-flex align-items-stretch"
                >                  
                  <div className="service-box text-center">
                    <i className="fa fa-th"></i>
                    <div className="service-desc">
                      <h3>Gabion Stone Pitching</h3>
                      <p>We do undertake internal and external architecture as well as civil engineering applications of gabions. Gabions are dry-packed stone walls contained neatly within wire mesh baskets.</p> 
                    </div>
                  </div>
                </div>
               {/* Gabion Stone Pitching END */}


              {/* Fencing Work (All Type) START */}
              <div
                  key={`mmm`}
                  className="col-md-4 col-sm-6 d-flex align-items-stretch"
                >                  
                  <div className="service-box text-center">
                    <i className="fa fa-align-justify"></i>
                    <div className="service-desc">
                      <h3>Fencing Work (All Type)</h3>
                      <p>We have built a reputation for maintaining the quality of work by using superior components. Our job execution meets the specs of approved regulatory agencies in the Sultanate of Oman.</p> 
                    </div>
                  </div>
                </div>
               {/* Fencing Work (All Type) END */}
         

              {/* Maintenance Services START */}
                <div
                  key={`mmm`}
                  className="col-md-4 col-sm-6 d-flex align-items-stretch"
                >                  
                  <div className="service-box text-center">
                    <i className="fa fa-wrench"></i>
                    <div className="service-desc">
                      <h3>Maintenance Services</h3>
                      <p>We have skilled and high experienced staff for performing the several maintenance services as below given</p>                
                      <div className="services_list"> 
                      <div className="list-styleq">
                      <ul  className="services_list"   >
                          <li>AC Maintenance</li>
                          <li>Painter</li>    
                          <li>Welder</li>
                          <li>Carpenter</li>                
                      </ul> 
                      </div>
                      </div>
                    </div>
                  </div>
                </div>
               {/* Maintenance Services END */}


              {/* Steel Structure Works (Fabrication & Erection) START */}
              <div
                  key={`mmm`}
                  className="col-md-4 col-sm-6 d-flex align-items-stretch"
                >                  
                  <div className="service-box text-center">
                    <i className="fa fa-industry"></i>
                    <div className="service-desc">
                      <h3>Steel Structure Works (Fabrication & Erection)</h3>
                      <p>SiCO provides Fabrication and Erection services of light and heavy steel structure for industrial requirements. Warehouses, airport facilities, cold rooms are some samples which are related with that area of service. In regards with steel structures, with our accumulated experience over the years, we offer a combined project management and services in the areas of design, fabrication and erection.</p> 
                      <div className="services_list"> 
                      <div className="list-styleq">
                      <ul>
                          <li key="">Fabrication and erection of Factories, Ware houses and stores.</li>
                          <li key="">Construction of Industrial structures with sandwich roofing </li>
                          <li key="">Car parking with bend pipes and profile sheet roofing</li>
                          <li key="">Grills and compound gates</li>   
                          <li key="">Refuse skips</li>                  
                      </ul> 
                      </div>
                      </div>
                    </div>
                  </div>
                </div>
               {/* Steel Structure Works (Fabrication & Erection) END */}
         

              {/* INDUSTRIAL & LAB CHEMICALS START */}
                <div
                  key={`mmm`}
                  className="col-md-4 col-sm-6 d-flex align-items-stretch"
                >                  
                  <div className="service-box text-center">
                    <i className="fa fa-glass"></i>
                    <div className="service-desc">
                      <h3>INDUSTRIAL & LAB CHEMICALS</h3>
                      <p>Permissible, Hazardous & Restricted Chemicals</p>                
                      <div className="services_list"> 
                      <div className="list-styleq">
                      <ul>
                          <li>Process Chemicals - Hydrochloric Acid, Caustic, Phosphoric Acid, Sulphuric Acid, etc</li>
                          <li>Lab Chemicals - All kind of AR & LR Grade Chemicals</li>    
                          <li>Water Waste Treatment Plant Chemicals</li>
                          <li>All kinds of Lab instruments - Gas Chromatography, PH Meter, HPLC, etc.</li>    
                          <li>All kinds of Lab Ware - Glassware - Non-Standard, ISO Standard & NABL Standard</li>
                          <li>Lab equipment and consumables - Septa, Filter paper, gloves, caps, safety glasses, etc.</li>                     
                      </ul> 
                      </div>
                      </div>
                    </div>
                  </div>
                </div>
               {/* INDUSTRIAL & LAB CHEMICALS END */}
         

              {/* AUTOMOTIVE & INDUSTRIAL COATINGS START */}
                <div
                  key={`mmm`}
                  className="col-md-4 col-sm-6 d-flex align-items-stretch"
                >                  
                  <div className="service-box text-center">
                    <i className="fa fa-paint-brush"></i>
                    <div className="service-desc">
                      <h3>AUTOMOTIVE & INDUSTRIAL COATINGS</h3>                
                      <div className="services_list"> 
                      <div className="list-styleq">
                      <ul>
                          <li>Represent <b>HEMPEL-PERMOGLAZE</b> Protective Industrial & Marine Coatings</li>
                          <li>Represent <b>THERMILATE</b> Metal Roof Waterproofing.</li>    
                          <li>Represent <b>AKZO-NOBEL AUTOMOTIVE, NIPPON, RITVER</b> PAINTS</li>
                          <li>Sole agents of Nippon Paints in Oman, with own mixing unit.</li> 
                          <li>Affordable and attractive prices with wide variety of selections.</li>                     
                      </ul> 
                      </div>
                      <img src="img/services/Picture1.jpg" className="img-responsive" alt="" /> 
                      </div>
                    </div>
                  </div>
                </div>
               {/* AUTOMOTIVE & INDUSTRIAL COATINGS END */}

               
               {/* DECORATIVE, INDUSTRIAL PROTECTIVE & COATING START */}
                <div
                  key={`mmm`}
                  className="col-md-4 col-sm-6 d-flex align-items-stretch"
                >                  
                  <div className="service-box text-center">
                    <i class="fa fa-industry" aria-hidden="true"></i>
                    <div className="service-desc">
                      <h3>DECORATIVE, INDUSTRIAL PROTECTIVE & COATING</h3>                
                      <div className="services_list"> 
                      <div className="list-styleq">
                      <img src="img/services/Picture6.png" className="img-responsive" alt="" /> 
                      <img src="img/services/Picture7.png" className="img-responsive" alt="" /> 
                      <img src="img/services/Picture8.png" className="img-responsive" alt="" /> 
                      </div>
                      </div>
                    </div>
                  </div>
                </div>
               {/* DECORATIVE, INDUSTRIAL PROTECTIVE & COATING END */}

               
               {/* LEOCH INDUSTRIAL TRACTION BATTERIES START */}
                <div
                  key={`mmm`}
                  className="col-md-4 col-sm-6 d-flex align-items-stretch"
                >                  
                  <div className="service-box text-center">
                    <i className="fa fa-battery-half"></i>
                    <div className="service-desc">
                      <h3>LEOCH INDUSTRIAL TRACTION BATTERIES</h3>                
                      <div className="services_list"> 
                      <div className="list-styleq">
                      <img src="img/services/Picture9.png" className="img-responsive" alt="" /> 
                      <img src="img/services/Picture10.jpg" className="img-responsive" alt="" /> 
                      </div>
                      </div>
                    </div>
                  </div>
                </div>
               {/* LEOCH INDUSTRIAL TRACTION BATTERIES END */}





              {/* INSTRUMENTATION START */}
                <div
                  key={`mmm`}
                  className="col-md-4 col-sm-6 d-flex align-items-stretch"
                >                  
                  <div className="service-box text-center">
                    <i className="fa fa-cogs"></i>
                    <div className="service-desc">
                      <h3>INSTRUMENTATION</h3>                
                      <div className="services_list"> 
                      <div className="list-styleq">
                      <ul>
                          <li><b>Pumps</b> - CNP, Investa, Grundfos, Densil, Grosvenor, Egger, Sulzer, Fristam, KSB, La-Gajjar etc. from India having tie up with their local distributors in India. Also can deal with International Brands for trading</li>
                          <li><b>Valves, Cylinders & Actuators</b> - SMC, Jeckon, Noregren, Tracs, Cair, Belimo, Entech Control, Elger Control, etc. from India having tie up with their local distributors in India. Also can deal with International Brands for trading.</li>    
                          <li><b>Pneumatic Fittings</b> - Festo, SMC, Noregren, Asco, Janatics, Tapco, Shavo Norgren, etc. from India having tie up with their local distributors in India. Also can deal with International Brands for trading with specific part number and complete technical specifications.</li>
                          <li><b>Automation Products</b> - Emerson, ABB, Schneider, Siemens, Digikey, etc. from India having tie up with their local distributors in India. Also can deal with International Brands for trading</li>                     
                      </ul> 
                      </div>
                      </div>
                    </div>
                  </div>
                </div>
               {/* INSTRUMENTATION END */}
         

              {/* MINING CONSULTANCY START */}
                <div
                  key={`mmm`}
                  className="col-md-4 col-sm-6 d-flex align-items-stretch" >                  
                  <div className="service-box text-center">
                    <i className="fa fa-download"></i>
                    <div className="service-desc">
                      <h3>MINING CONSULTANCY</h3>
                      <p></p>                
                      <div className="services_list"> 
                      <div className="list-styleq">
                      <ul>
                          <li>Exploration and Mining Licensing</li>
                          <li>Mine / Quarry planning services</li>    
                          <li>Drill and Blast Consultations</li>
                          <li>Open Pit Production Scheduling</li>    
                          <li>Continuous improvement strategies to enhance safety and productivity of Mining</li>
                          <li>Consultancy for Open Pit and Underground Mining – Commodity expertise Gold, Copper, Iron Ore, Gypsum, Lime Stone, Chromite etc</li>                     
                      </ul> 
                      </div>
                      </div>
                    </div>
                  </div>
                </div>
               {/* MINING CONSULTANCY END */}
         

              {/* MANPOWER & CLEANING SERVICES START */}
                <div
                  key={`mmm`}
                  className="col-md-4 col-sm-6 d-flex align-items-stretch"
                >                  
                  <div className="service-box text-center">
                    <i className="fa fa-user"></i>
                    <div className="service-desc">
                      <h3>MANPOWER & CLEANING SERVICES</h3>                
                      <div className="services_list"> 
                      <div className="list-styleq">
                      <ul>
                          <li>Supply of Manpower – Engineering Specialists, Operators, Construction workers, General Laborer etc.</li>
                          <li>Assist/handle logistics, domestic and international -including loading and unloading</li>    
                          <li>Cleaning services for corporate offices, private houses, shops, workshops etc</li>
                          <li>Comprehensive Cleaning Services are divided into cleaner periodic formal contracts.</li> 
                          <li>Cleaner constituent per day, weekly, monthly and yearly.</li>  
                          <br/> 
                          <p><b>Cleaning Services offered by the Establishment:</b></p>  
                          <li>House Keeping Services</li>
                          <li>Manpower Supply</li>
                          <li>General Services</li>
                          <li>Loading & Unloading Services</li>
                          <li>F & B Services </li>
                          <li>Bell desk skill staff provide</li>
                          <li>Industries area cleaning services</li>
                          <li>Clean and Vacuum Dust from floors (Sofas, curtains and carpets) with perfuming using machines and special materials</li>
                          <li>Cleaning and Polishing floor, glass windows, doors and partitions</li>
                          <li>Cleaning and Polishing offices and wood furnishings.</li>
                          <li>Cleaning and polishing all floors (Marbles, Ceramic Tiles) using floor cleaning machines</li>
                          <li>Cleaning painted walls and remove fingerprints from them by using form, dry</li>
                          <li>Cleaning gardens and swimming pools and stores</li>
                          <li>Cleaning workshops and buildings under constructions</li>
                          <li>Sterilization and cleanliness of water tanks</li>
                          <li>Specialize in the field of sterilization and cleanliness of water tanks, new and used and remove sediment and bacteria deposited in them.</li> 
                      </ul> 
                      </div>
                      </div>
                    </div>
                  </div>
                </div>
               {/* MANPOWER & CLEANING SERVICES END */}




          
        </div>

      </div>
    </div>
  );
};
