import React from "react";
 
export const Contact = (props) => {

  
  return (
    <div>
      <div id="contact">
        <div className="container">



        <div className="section-title">
          <h2>LET’S CONNECT</h2>We’d love to help you!
          </div>
          <div className="col-md-8">
            <div className="row">
             


            <div className="contact-item">
               
               <p>
                 <span>
                   <i className="fa fa-map-marker"></i><b>OMAN</b>
                 </span>
                 New Industrial Area,<br/>
                 P.B. No.1423,<br/> 
                 Salalah 211,<br/>
                 Sultanate of Oman.<br/>
                 <i className="fa fa-phone"></i>  +968 90997331, 91118712 <br/>
                 <i className="fa fa-envelope-o"></i>  info@springfieldoman.com<br/>
                 <iframe title="Google Map" src="https://maps.google.com/maps?q=17.01459503173828%2C54.04823684692383&t=m&z=15&output=embed&iwloc=near&w=425&h=350" allowfullscreen></iframe> 
                 
               </p>
             </div>
            
            </div>
          </div>
          <div className="col-md-3 col-md-offset-1 contact-info"> 
            <div className="contact-item">
              
             
            
            <p>
                <span>
                  <i className="fa fa-map-marker"></i> <b>UAE</b><br/>
                </span>
                609,Office Court Bulding<br/>
                Near oud metha metro<br/>
                Opp. Rashid Hospital.<br/>
              </p>


              <p>
                <span><br/>
                  <i className="fa fa-map-marker"></i> <b>INDIA</b><br/>
                </span>
                36 Orchid, Jhalariya<br/>  
                Indore 452016<br/>
                Madhya Pradesh.<br/>
                <i className="fa fa-phone"></i> +91-9575713333
              </p>
              </div>
          </div>
          <div className="col-md-12">          
              <div className="social">
                <ul>
                  <li>
                    <a target="_blank" rel="noreferrer" href={props.data ? props.data.facebook : "/"}>
                      <i className="fa fa-facebook"></i>
                    </a>
                  </li>
                  <li>
                    <a target="_blank" rel="noreferrer" href={props.data ? props.data.linkedin : "/"}>
                      <i className="fa fa-linkedin"></i>
                    </a>
                  </li>
                </ul>
                &copy; 2024 Spring Field International Company LLC
              </div>            
          </div>
        </div>
      </div> 
    </div>
  );
};
